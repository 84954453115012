import {ReactComponent as Logo} from 'assets/img/logo.svg';
import {ReactComponent as Location} from 'assets/img/icon/Location.svg';
import {ReactComponent as Mail} from 'assets/img/icon/Mail.svg';
import { EventInfo } from 'components/shared';
import {ReactComponent as Youtube} from 'assets/img/icon/youtube.svg';
import {ReactComponent as Vk} from 'assets/img/icon/vk.svg';
import {ReactComponent as Telegram} from 'assets/img/icon/telegram.svg';
import useWindowDimensions from 'hooks/windowSize';
import { jumpToSection } from 'components/shared/helpers/helpers';
import { ModalData } from './ModalData/ModalData';
import { publicOffer } from 'components/constants/PublicOffer';
import { policyData } from 'components/constants/PolicyData';

export const FOOTER_INFO = [
    {
        id: 0,
        Icon: <Mail />,
        description: "info.logcap@gmail.com",
        link: 'mailto:info.logcap@gmail.com',
    },
    {
        id: 1,
        Icon: <Location fill='#181818' />,
        description: 'г. Воронеж, проспект Революции, 38',
        link: "https://yandex.ru/maps/org/marriott_hotel/74896326928/?ll=39.206504%2C51.667870&z=17.01",
    }
]


const SOCIAL_LINKS = [
    {
        title: "YouTube",
        href: "https://www.youtube.com/@LOGCAPITAL",
        icon: <Youtube className="fill-black" />,
    },
    {
        title: "VK",
        href: "https://vk.com/logcap",
        icon: <Vk className="fill-black" />,
    },
    {
        title: "Telegram",
        href: "https://t.me/logcap",
        icon: <Telegram className="fill-black" />,
    },
];


export const Footer = ({refs}:any) => {

    const { width } = useWindowDimensions();

    return (
        <div className='flex items-start justify-between flex-col relative lg:flex-row max-lg:items-center'>
            <div className='w-full md:w-[30%] pl-0 md:pl-12 '>
                <div className='border-b border-black max-md:flex max-md:items-center max-md:flex-col '>
                    <Logo fill='black' className='mb-8 max-md:mb-14 mt-2' />
                    {width >= 1024 && <p className='pb-6 pt-10 max-lg:pt-4'> Проп-трейдинговая компания</p>}
                </div>
                <div className='pt-6 flex justify-evenly md:justify-between'>
                   {
                    SOCIAL_LINKS.map((el, index) => (
                        <a key={index} href={el.href}>
                            {el.icon}
                        </a>
                    ))
                   }
                </div>
            </div>
            <div className='w-[70%] xl:w-[65%] max-lg:w-full pl-0 lg:pl-12 max-md:w-full mt-6 lg:mt-0 flex flex-col items-center justify-center'>{/* className='absolute left-[450px] max-md:relative max-md:left-0 max-md:pt-12' */}
                <div className='flex flex-col  md:flex-row w-full items-center md:items-start justify-between border-b border-black pb-12' >
                    <div className='hidden md:grid grid-flow-col gap-8 grid-rows-3  '>
                       {  
                        refs?.map((el:any) => (
                                <button className='text-left' key={el.id} onClick={jumpToSection(el.ref, width)}>
                                    {el.name}
                                </button>
                            ))
                        }
                        <a  href="https://logcap.ru/" className='text-black footerNavButton'>
                            Наш Сайт
                        </a>
                    </div>
                    <div>
                        <EventInfo arrData={FOOTER_INFO} styled='flex-col text-black' />
                    </div>

                    <div className='max-md:pt-8'>
                        <p>ООО "ЛОГ КЭПИТАЛ"</p>
                        <p className='py-8'>ИНН 3666260568</p>
                        <p>ОГРН 1213600022855</p>
                    </div>
                </div>

                <div className='w-full flex flex-col md:flex-row items-center justify-between py-14'>
                    <ModalData title='Политика конфиденциальных данных' header='Политика конфиденциальности платформы LOG CAPITAL' data={policyData} />
                    <ModalData title='Публичная оферта' header="Публичная оферта ООО «ЛОГ КЭПИТАЛ» 2024 г." data={publicOffer} />
                    <p className='text-end w-auto md:w-44'> {` © 2019 - ${new Date().getFullYear()} LogCapital`}</p>
                </div>                    
            </div>
        </div>
    )
}
