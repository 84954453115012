import React from "react";
// import { useMediaQuery } from "src/hooks/MediaQuery";
import clsx from "clsx";

export const Step = ({ children, step, title, description, lastChild }: any) => {
    // const isSm = useMediaQuery(425);

    return (
        <div
            className={`text-white border-t mr-12 grow ${
                lastChild
                    ? "mr-12 w-full lg:mr-0 lg:w-[300px] ": clsx("mr-12 lg:border-t border-white2", "w-full lg:w-[294px] xl:w-[300px] ")}`}>
            <div
                className={`pb-6 pr-6 relative top-6  lg:pb-0  ${
                    lastChild ? "border-b w-full lg:border-b-0 mr-6 lg:mr-0" : "lg:w-[334px] xl:w-[418px]  mr-6 lg:border-r border-white2"
                }`}>
                <p className={"uppercase text-h4 mb-8"}>{title}</p>
                <p className={"text-p1 whitespace-pre-wrap"}>{description}</p>
            </div>
        </div>
    );
};
