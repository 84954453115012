import clsx from 'clsx';
import { ReactElement } from 'react'

import { IconInfo } from 'components/baseComponents/iconInfo/IconInfo';

export enum DATA_INFO_ENUM {
    PHONE = 'phone',
    MAIL = 'mail',
    LINK = 'link',
    SPAN = 'span'
}

export interface ItemInfo {
    id: number;
    Icon: ReactElement<any, any>;
    description: string;
    link?: string;
}

export interface EventInfoProps {
    arrData: ItemInfo[]
    styled?: string;
}


export const EventInfo = (props: EventInfoProps) => {
    const { styled, arrData } = props;

    const selectInfo = (data:ItemInfo) => {
        const { Icon, description, id, link } = data;
        if (link) {
            return <a key={id} href={link} className={clsx(styled)}>
                    <IconInfo  Icon={Icon} description={description}  isAdditional={id === 1}  style={styled} />
                </a>
        } else {
            return <IconInfo key={id} Icon={Icon} description={description} isAdditional={id === 1}  style={styled} />
        }
    }

    return (
        <div key={Math.floor(Math.random() * 10000)} className={clsx(`flex ${!!styled ? styled : 'flex-col md:flex-row'}` )}>
            {
                arrData.map((el) => (
                    selectInfo(el)
                ))
            }
        </div>
    )
}
