import React from "react";
import {ReactComponent as LogoSvg} from "assets/img/logo.svg";

export const Logo = ({ children }: any) => {

    return (
        <div className={"flex cursor-poiner"}>
            <div className="flex items-center" >
                <LogoSvg fill='white'/>
            </div>

            {/* {isLg && ( */}
                <div
                    className={
                        "flex ml-8 pl-8 border-l border-[rgba(255, 255, 255, 0.5)] text-white w-[164px] text-p2  items-center opacity-70"
                    }>
                    Проп-трейдинговая компания
                </div>
            {/* )} */}
        </div>
    );
};
