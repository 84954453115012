import { List } from "components/baseComponents"

export const PROGRAM_LIST = [
  {
    id: 0,
    time: `9:30`,
    title: 'Начало работы стойки регистрации'
  },
  {
    id: 1,
    time: `10:00`,
    title: <p>Кофе Брейк <br/> «Доброе утро»</p>
  },
  {
    id: 2,
    time: `11:00`,
    title: 'Открытие Конференции'
  },
  {
    id: 3,
    time: `11:30`,
    title: <p>Сергей Слукин <br/> Тема - «Роль брокера в работе Проп-компании?»</p>
  },
  {
    id: 4,
    time: `12:00`,
    title: <p>Стас Чудаев <br/> Тема - «О чем не рассказывают трейдеры?»</p>
  },
  {
    id: 5,
    time: `12:30`,
    title: 'Ответы на вопросы'
  },
  {
    id: 6,
    time: `13:00`,
    title: <p>Сергей Пирогов <br/> Тема - «Как из аналитика стать управляющим?»</p>
  },  
  {
    id: 7,
    time: `13:30`,
    title: <p>Дмитрий Жильников <br/> Тема - «Новостные ситуации на рынке акций, корпоративные события эмитентов: как на этом заработать?»</p>
  },
  {
    id: 8,
    time: `14:00`,
    title: <p>Кофе-Брейк <br/> «Обед»</p>
  },
  {
    id: 9,
    time: `15:00`,
    title: <p className="w-2/3">Сергей Алексеев <br/> Тема - «Трейдер ТВ: как дисциплинированный выпуск контента ведёт к росту эффективности на бирже?»</p>
  },
  {
    id: 10,
    time: `15:30`,
    title: <p>Антон Клевцов <br/> Тема - «Как сделать из трейдинга реальный источник дохода?»</p>
  },
  {
    id: 11,
    time: `16:00`,
    title: 'Ответы на вопросы'
  },
  {
    id: 12,
    time: `16:30`,
    title: <p> Игорь Дарсалия <br/> Тема: «Кейс будущего финансового пузыря на российском фондовом рынке»</p>
  },
  {
    id: 13,
    time: `17:00`,
    title: <p>Денис Стукалин <br/> Тема - «Современный и эффективный крипто-трейдинг 2025»</p>
  },
  {
    id: 14,
    time: `17:30`,
    title: 'Завершающее слово'
  },
  {
    id: 15,
    time: `18:00`,
    title: 'Начало вечеринки'
  },
]

export const EventProgram  = () => {
  return (
    <div className='mt-24 w-full flex flex-col'>
      {
        PROGRAM_LIST.map((el, index) => (
          <List key={index} lastElement={PROGRAM_LIST.length === index + 1}>
              {
                <div className="w-full flex flex-col items-start justify-between lg:flex-row lg:items-center py-6" >
                  <p className="pl-6 inline-block">{el.time}</p>
                  <div className="lg:w-2/3 text-start py-6 lg:py-8 " >{el.title}</div>
                </div>
            }
          </List>
        ))
      }
    </div>
  )
}
