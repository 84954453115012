
import "./styles.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Main } from "pages/Main";
import { SuccessBuyTicket } from "pages/SuccessBuyTicket";
import { ErrorBuyTicket } from "pages/ErrorBuyTicket";
import { NotFoundPage } from "pages/NotFoundPage";

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/successBuyTicket" element={<SuccessBuyTicket />} />
        <Route path="/errorBuyTicket" element={<ErrorBuyTicket />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter> 
  );
}

export default App;