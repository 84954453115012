import React, {  useState } from 'react'
import {ReactComponent as Logo} from "assets/img/logo.svg";
import {ReactComponent as Cross} from "assets/img/icon/Cross.svg";
import {ReactComponent as Burger} from "assets/img/icon/Burger.svg";
import {ReactComponent as Arrow} from "assets/img/icon/arrowTop.svg";
import useWindowDimensions from 'hooks/windowSize';
import { jumpToSection } from 'components/shared/helpers/helpers';


export const Header = ({refs}:any) => {
    const { width } = useWindowDimensions();
    const [isChecked, setIsChecked] = useState(false)

    return (
      <header className="sticky top-[-1px] z-50 s:absolute headerContainer">
            <div className="container">
    			<div className="headerLogo">
                    <div className='logo'>
    				    <Logo fill='white' />
                        <div className='logoDescription'>
                            <p>Проп-трейдинговая компания</p>
                        </div>

                        <label htmlFor="open">
    				    	<span className="hidden-desktop z-20">
                                    <button className='pointer-events-none' >
                                        { isChecked ?  <Cross /> : <Burger/>} 
                                    </button>
                            </span>
    				    </label>
                    </div>
                </div>
    		    <div className="headerNav">
    			    <input checked={isChecked} type="checkbox" name="" id="open" onChange={(event) => setIsChecked(event.target.checked)}  />
    			    <nav>
                      <div className='wrapNavButton'>
                          {
                              refs?.map((el:any) => (
                                  <button className='navButton' key={el.id} onClick={jumpToSection(el.ref, width, isChecked,setIsChecked)}>
                                        <div className={'navButtonTitle'}>
                                            {el.name}
                                            {width < 480 &&  <Arrow />}
                                        </div>
                                  </button>
                              ) )
                          }
                      </div>
    			    </nav> 
    		    </div>
    		</div>
    	</header>
    )
}
