import clsx from 'clsx';
import { ReactElement } from 'react'

export interface IconInfoProps {
    Icon: ReactElement<any, any>
    description: string;
    isAdditional?: boolean;
    style?: string;
}

export const IconInfo = (props: IconInfoProps) => {
    const { Icon, description, style, isAdditional } = props;
    return (
      <div className={clsx('flex flex-row',  isAdditional && (!!style ? 'my-8' : "mx-0 my-8 md:mx-8 md:my-0"), )}>
          {Icon}
          <span className='pl-2'>{description}</span>
      </div>
    )
}
