import { BlockExtraType, ContentBlock } from "components/shared/publicInfo/PublicInfo";
            // "Адрес электронной почты: info.logcap@gmail.com",


export const publicOffer: ContentBlock[] = [
    {
        lines: [
            "Общество с ограниченной ответственностью «ЛОГ КЭПИТАЛ» (ИНН 3666260568  ОГРН 1213600022855), в лице генерального директора Логунова Сергея Сергеевича, действующего на основании Устава и находящееся по адресу: г. Воронеж, ул. Комиссаржевской, д. 10, этаж 12, помещение 5, (далее – «Компания»), с одной стороны предлагает любому физическому лицу (далее 	– «Клиент»), с другой стороны, заключить договор об участии в оффлайн-мероприятии на нижеприведенных условиях.",
        ],
    },
    {
        header: "Термины",
        lines: [
            "•	Платформа - веб-сайт https://logcap.ru/courses, предоставляющий доступ к базе данных, видеоматериалов, мероприятиям, и консультативным услугам.",
            "•  Компания – юридическое лицо Общество с ограниченной ответственностью «ЛОГ КЭПИТАЛ» (ИНН 3666260568  ОГРН 1213600022855)",
            "•  Клиент – совершеннолетнее физическое лицо, в установленном порядке зарегистрированное на Платформе, согласовавшее настоящую Оферту и Политику конфиденциальности.",
            "•  Контент – размещенные на Платформе информационные материалы, в том числе записи Мероприятий, консультаций и иных форм воспроизведения информации по выбору Компании.",
            "•  Мероприятие – конференция «LogCapital conference», которая проводится 24.08.2024 г. по адресу: г. Воронеж, проспект Революции, 38, длительность конференции 1 день.",
            "•  Спикеры мероприятия – третьи лица, которые оказывают услуги от имени Компании полностью или частично и за которого Организатор несет ответственность как за свои действия. Спикеры указаны на сайте мероприятия.",
            "•  Билет-приглашение – документ, подтверждающий факт оплаты услуг Компании и предоставляющий право Клиенту посетить мероприятие, который содержит в себе информацию о наименовании мероприятия, дате и времени его проведения, наименование оплаченного Тарифа, а также средства индивидуализированной идентификации (серию, номер или штрихкод, qr-код).",
            "•  Регистрация – процедура внесения Персональных данных Клиента в специальную форму на Сайте, необходимая для получения доступа к Контенту на Платформе, а также для доступа Пользователя к персонализированным сервисам Сайта.",
        ],
        extra: [
            {
                line: 0,
                start: 23,
                end: 48,
                href: "https://logcap.ru/courses",
                type: BlockExtraType.LINK,
            },
        ],
    },
    {
        header: "1. Общие положения",
        lines: [
            "1.1	Настоящая публичная оферта (далее - Оферта) является официальным предложением Компании в адрес любого физического лица заключить соглашение об оказании услуг по обеспечению участия Клиента в Конференции «LogCapital Conference».",
            "1.2	Использование настоящего Сайта означает полный и безоговорочное принятие Клиентом условий Политики Конфиденциальности и выдачу согласия на обработку персональных данных на изложенных в Политике условиях. С момента оплаты билета-приглашения в соответствующем разделе Платформы условия настоящего Договора считаются согласованными сторонами. ",
            "1.3	В рамках настоящего Договора Компания обязуется оказать Клиенту услуги по обеспечению участия Клиента в конференции «LogCapital Conference» в соответствии с билетом-приглашением Клиента, а Клиент обязуется оплатить билет-приглашение в согласованных сторонами размере и сроки.",
            "1.4	Мероприятие по теме «Трейдинг и инвестиции» проводится в очном формате и требует непосредственного участия Клиента в мероприятии. По окончании конференции Клиент вправе получить подготовленную Компанией видеозапись Конференции.",
            "1.5	Компания вправе проводить Мероприятие как самостоятельно, так и путем привлечения к проведению Мероприятия третьих лиц.",
        ],
    },
    {
        header: "2. Порядок оказания услуг.",
        lines: [
            "2.1	Для приобретения билета-приглашение на мероприятие по установленному на Сайте мероприятия тарифу Клиент обязуется предоставить Компании следующие данные:",
            "2.1.1	ФИО Клиента;",
            "2.1.2	Номер телефона;",
            "2.1.3	Адрес электронной почты;",
            "2.2	Заявка Клиента должна быть направлена не позднее 10 дней до анонсированного Мероприятия.",
            "2.3	В случае одобрения заявки Компания направляет на адрес электронной почты Клиента ссылку для оплаты услуг Компании через соответствующий платёжный сервис «Ю-КАССА»",
            "2.4	Стоимость Мероприятия, объем услуги, дата проведения мероприятия, его программа и иные условия оказания услуги размещаются Компанией на Сайте мероприятия по адресу: https://conference.logcap.ru",
            "2.5	Компания оставляет за собой право на отказ в одобрении заявки по своему усмотрению и без обязанности информировать Клиента или третьи лица о причинах такого решения.",
            "2.6	Компания не несет ответственности за достоверность сведений, предоставленных Клиентом при подаче заявки, любая ответственность, связанная с недостоверностью таких сведений лежит на Клиенте.",
            "2.7	Компания не является платежным агентом при приеме оплаты Мероприятий согласно пп. 1, 4 ч. 2 ст. 1 Федерального закона от 03.06.2009 № 103-ФЗ «О деятельности по приему платежей физических лиц, осуществляемой платежными агентами», не получает доступа, не собирает и не обрабатывает платёжную информацию Клиента. ",
            "2.8	При оплате заказа банковской картой возврат денежных средств производится на ту же самую карту, с которой был произведён платёж.",
            "2.9	Клиент самостоятельно несет ответственность за ошибки, которые допущены им при оплате Услуг. Компания не несет ответственности за убытки и иные неблагоприятные последствия, которые могут возникнуть у Клиента и/или третьих лиц в случае неправильного указания назначения или реквизитов платежа.",
            "2.10	Договор считается заключенным с момента оплаты Клиентом участия в Мероприятии. Моментом оплаты считается момент поступления денежных средств на расчетный счет Компании оплаты услуг в полном объеме.",
            "2.12	Клиент обязуется самостоятельно прибыть к месту проведения мероприятия в назначенное время начала мероприятия.",
            "2.13	В случае изменения даты, времени или места проведения мероприятия Компания уведомляет об этом Клиента путем размещения такой информации на сайте мероприятия, а также путем направления информации на электронную почту Клиента, указанную при покупке Билета-приглашения. Такое уведомление осуществляется не менее чем за 24 часа до начала мероприятия.",
            "2.14   Клиент самостоятельно отслеживают всю информацию, связанную с проведением мероприятия путем ознакомления с ней на сайте мероприятия и/или путем прочтения письма от Компании, направленного на электронную почту.",
            "2.15   Клиент по своему выбору посещает выступления различных Спикеров. Отказ от посещения выступлений отдельных Спикеров не является основанием для возврата денежных средств за приобретенный билет-приглашение полностью или в части.",
            "2.16   В течение 24 часов после оплаты Клиенту на электронную почту, оставленную при регистрации заказа, поступает Билет-приглашение, который предоставляет право участия в мероприятии. Если в указанный срок Билет-приглашение не поступил, Клиент самостоятельно обязан обратиться к Компании по адресу электронной почты info.logcap@gmail.com с указанием даты оплаты, фамилии, имени, номера телефона и электронной почты, оставленных при регистрации заказа. Отсутствие такого обращения свидетельствует о том, что Клиент получил Билет-приглашение и чек об оплате.",
            "2.17   Для участия в мероприятии Клиент обязан распечатать полученный на электронную почту, оставленную при оформлении заказа, Билет-приглашение и чек об оплате. ",
            "2.18   При входе на мероприятие Клиент обязан зарегистрироваться путем предъявления билета-приглашения и чека об оплате представителю Компании, при необходимости уточнить сведения, оставленные при оформлении заказа (фамилия, имя, телефон, адрес электронной почты).",
            "2.19   При успешной регистрации представитель Организатора выдает Клиенту пропуск в соответствии с выбранным Тарифом.",
            "2.20   Услуги считаются оказанными с момента предоставления Клиенту возможности участия в мероприятии после успешной регистрации вне зависимости от того какое количество выступлений Спикеров посетил Клиент, либо не посетил вовсе.",
            "2.21   Мероприятие считается проведенным в полном объеме с момента выступления 70% спикеров, заявленных на Сайте, вне зависимости от количества выступлений, которые посетил Клиент;",
            "2.22   Акт оказанных услуг сторонами не подписывается. Если в течение 1 (одного) календарного дня, следующего за днем проведения мероприятия, Клиент не заявил Организатору возражений относительно качества и объема услуг, то услуги считаются как принятыми в полном объеме без претензий.",
            "2.23   Клиент гарантирует достоверность предоставляемой информации, предусмотренной 2.1. Договора, в случае изменения тех или иных данных, предусмотренных Договором, Клиент обязуется внести актуальные данные в соответствующем разделе Платформы в течение 10 дней с момента таких изменений, в противном случае Компания имеет право по своему усмотрению приостановить доступ Клиента к Платформе или удалить учётную запись Клиента.",
            "2.24   Клиент обязуется получить оказываемые услуги лично. Передача билета-приглашения третьим лицам запрещена. Компания вправе не допустить к участию в мероприятии лицо, чьи данные не совпадают с указанными в билете-приглашение.",
            "2.25   Компания оставляет за собой право запрашивать дополнительные сведения о Клиенте в целях предоставления ответа на запросы уполномоченных учреждений, связанных с деятельностью Компании и оказываемыми ей услугами.",
        ],
        extra: [
            {
                line: 6,
                start: 169,
                end: 200,
                href: "https://conference.logcap.ru",
                type: BlockExtraType.LINK,
            },
        ],
    },
    {
        header: "3. Условия возврата.",
        lines: [
            "3.1	Клиент вправе в любое время отказаться от получения услуг по договору без указания причин. В этом случае возврат денежных средств производится в соответствии с Постановлением Правительства РФ от 18 сентября 2020 года № 1491, а именно:",
            "3.1.1  Если Клиент отказался от участия в мероприятии по собственной инициативе, за исключением случаев болезни самого Клиента, смерти члена семьи или близких родственников в соответствии с Семейным Кодексом РФ, то возврат осуществляется в следующем объеме:",
            "3.1.1.1    100% стоимости Билета-приглашения при получении заявления о возврате билета-приглашения и отказе от участия в мероприятии не менее чем за 10 календарных дней до даты его проведения. При этом такое заявление должно быть подано путем направления на электронную почту тех. поддержки, указанную в оферте с приложением документов, предусмотренных п. 3.6 настоящей оферты.",
            "3.1.1.2    50% стоимости Билета-приглашения при получении заявления о возврате билета-приглашения и отказе от участия в мероприятии не позднее чем за 5 календарных дней до даты его проведения. При этом такое заявление должно быть подано путем направления на электронную почту тех. поддержки, указанную в оферте с приложением документов, предусмотренных п. 3.6 настоящей оферты.",
            "3.1.1.3    30% стоимости Билета-приглашения при получении заявления о возврате билета-приглашения и отказе от участия в мероприятии не позднее чем за 3 календарных дня до даты его проведения. При этом такое заявление должно быть подано путем направления на электронную почту тех. поддержки, указанную в оферте с приложением документов, предусмотренных п. 3.6 настоящей оферты.",
            "3.1.1.4    0% стоимости Билета-приглашения при получении заявления о возврате билета-приглашения и отказе от участия в мероприятии менее чем за 3 календарных дня до даты его проведения.",
            "3.1.1.5    Билеты-приглашения, реализованные Компанией в рамках специальных программ и акций, предусматривающих особые условия приобретения билетов-приглашений, (в том числе льготы, скидки, розыгрыши), не приниматься к возврату.",
            "3.2    Возврат в размере 100% стоимости Билета-приглашения осуществляется при получении заявления о возврате билета-приглашения и отказе от участия в мероприятии по причине болезни Клиента, смерти члена его семьи или близкого родственника, при предоставлении документов, подтверждающих указанные факты. При этом такое заявление должно быть подано не позднее даты проведения мероприятия путем направления на электронную почту тех. поддержки, указанную в оферте с приложением документов, предусмотренных п. 3.4 настоящей оферты.",
            "3.3    Если отмена мероприятия произошла по вине Организатора, то порядок возврата денежных средств за Билет-приглашение осуществляется в соответствии с Постановлением Правительства РФ от 18 сентября 2020 года № 1491.",
            "3.4    При принятии решения о возврате денежных средств Клиенту Организатор аннулирует билет-приглашение Клиента и исключает его из списка участников мероприятия.",
            "3.5    Заявление о возврате билета-приглашения и денежных средств (признается заявлением о расторжении настоящего договора) должно содержать контактные данные Клиента (телефон, электронная почта), указанные при оформлении заказа, паспортные данные Клиента, дату и сумму оплаты, а также полные банковские реквизиты для перечисления средств (в том числе ИНН Клиента). Заявление должно быть собственноручно подписано Клиентом, отсканировано и направлено Компании на электронную почту, указанную в настоящем договоре. К заявлению должны быть приложены: электронный билет-приглашение, чек об оплате.",
            "3.6    В случае возврата билета-приглашения по причине болезни или смерти члена семьи или близкого лица дополнительно к заявлению прикладывается больничный лист или свидетельство о смерти, а также документ, подтверждающий родство с умершим. При невозможности предоставить такие документы сразу, Клиент обязан направить заявление о возврате билета и денежных средств с указанием причины возврата не позднее дня проведения мероприятия, при этом срок предоставления дополнительных документов составляет 14 календарных дней с даты направления заявления. Если в указанный срок документы не поступят, то заявление рассматривается по общим правилам.",
            "3.7    В случае нарушения Клиентом указанных в настоящем пункте условий, срок возврата может быть увеличен Организатором на период устранения Клиентом допущенных нарушений.",
        ],
    },
    {
        header: "4. Права и обязанности Сторон.",
        lines: [
            "4.1.	Компания обязуется:",
            "4.1.1  Предоставить доступ Клиенту к оплаченному в соответствии с Договором Мероприятию.",
            "4.1.2  Уведомить Клиента об изменении даты, места и времени проведения мероприятия не менее, чем за 24 часа до начала такого мероприятия путем направления ему сообщения на электронную почту и размещения и информации на Сайте.",
            "4.1.3  Обрабатывать персональные данные Клиента в соответствии с требованиями законодательства РФ и условий Политики Конфиденциальности.",
            "4.1.4  Осуществить возврат денежных средств в порядке и сроки, предусмотренные договором и законодательством РФ.",
            "4.2    Компания вправе:",
            "4.2.1  В любое время без согласия Клиента дополнять, изменять, обновлять, модифицировать, адаптировать программу Мероприятия, изменять дату и место проведения Мероприятия полностью или в части, изменять состав Спикеров.",
            "4.2.2  Требовать от Клиента добросовестного исполнения договора, уважительного отношения к Спикерам, представителям Компании или иным участникам Конференции.",
            "4.2.3  В одностороннем порядке расторгнуть настоящий договор и удалить Клиента с мероприятия в случае существенного нарушения им условий настоящего соглашения и общепринятых норм этики и морали, в том числе, но не ограничиваясь: агрессивным, шумным, неуважительным или иным поведением, создающим помехи к проведению Мероприятия, нахождением Клиента в состоянии алкогольного, наркотического или токсического опьянения, препятствовании проведению мероприятия путем выкриков, шума во время выступления, необоснованных высказываний в адрес Спикеров, Компании или других участников конференции, равно как и несогласованная с организаторами рекламная, образовательная или агитационная деятельность. При таких обстоятельствах Клиент обязуется возместить все причинённые своими действиями убытки, как реальные, так и причинившие ущерб деловой репутации Компании, Спикеров или других Клиентов.",
            "4.3    Клиент обязуется:",
            "4.3.1  Самостоятельно и своевременно знакомиться с условиями проведения Мероприятий, с изменениями указанных условий, а также с актуальной редакцией Договора после акцепта оферты.",
            "4.3.2  Добросовестно выполнять все действия, предусмотренные программой Мероприятия. Клиент не вправе давать каких-либо указаний Компании, в том числе в отношении порядка проведения Мероприятия.",
            "4.3.3  Незамедлительно уведомить Компанию об изменении или предстоящем изменении любых данных Клиента, ранее указанных при приобретении Билета-приглашения, путем направления сведений об указанном изменении данных на адрес электронной почты Компании, указанный в настоящем соглашении;",
            "4.3.4  Не совершать действия от имени другого, реально существующего или существовавшего лица, не указывать персональную информацию третьих лиц, не использовать информацию третьих лиц каким-либо иным способом, не соответствующим требованиям законодательства Российской Федерации. Ответственность за указанные действия, в том числе причинившие убытки Компании, несет Клиент;",
            "4.3.5  Не использовать информацию, полученную на мероприятии, каким-либо иным образом, кроме как для личного пользования.",
            "4.3.6  Не копировать Билет-приглашение, а также самостоятельно принять все необходимые меры для сохранности и нераспространения Билета-приглашения.",
            "4.3.7  Клиент обязан сохранять пропуск, билет-приглашение и чек об оплате в течение всего срока проведения мероприятия, т.к. указанный пропуск является основанием для доступа в различные места выступления Спикеров. Клиент имеет право посещать только те залы и выступления спикеров, которые предусмотрены соответствующим тарифом.",
            "4.3.8  При утере пропуска Клиент самостоятельно обязан обратиться к представителю Компании за получением нового пропуска с предъявлением билета-приглашения и чека об оплате. За повторную выдачу пропуска может взыматься дополнительная плата.",
            "4.4    Клиент вправе:",
            "4.4.1  Принимать участие в оплаченном в установленном Договором порядке Мероприятии.",
            "4.4.2  Клиент имеет право обратиться с заявлением о возврате билета-приглашения и оплаченных денежных средств в соответствии с правилами, установленными настоящим договором.",
            "4.4.3  Самостоятельно принимать решения о целесообразности использования в своей жизни/деятельности предложенной в рамках мероприятия информации.",

        
        ],
    },
    {
        header: "5. Интеллектуальные права.",
        lines: [
            "5.1	Все видеозаписи, информационные материалы, предоставляемые Компанией Клиенту в процессе оказания услуг, а также результаты фото- и видеосъемки, полученные Компанией во время оказания услуг, признаются результатами интеллектуальной деятельности Компании. Авторские права, а также права, смежные с авторскими, исключительные права, принадлежат Компании. Компания вправе проводить фото- и видеосъемку процесса оказания Услуг и использовать полученные материалы по своему усмотрению.",
            "5.2	Клиент вправе вести фотосъемку, видеосъемку мероприятия частично длительностью не более 3 минут, подключать онлайн трансляции и стримы длительностью не более 5 минут в период проведения мероприятия.",
            "5.3	Клиент вправе использовать материалы, являющиеся результатами интеллектуальной деятельности, исключительными правами на которые обладает Компания, исключительно в личных целях, в рамках, установленных ст. 1273 ГК РФ.",
            "5.4	Использование результатов интеллектуальной деятельности без письменного согласия является нарушением исключительного права Компании, что влечет за собой гражданскую, административную и уголовную ответственность в соответствии с действующим законодательством Российской Федерации.",
        ],
    },
    {
        header: "6. Ответственность сторон.",
        lines: [
            "6.1	В случае неисполнения или ненадлежащего исполнения своих обязательств, стороны несут ответственность в соответствии с действующим законодательством РФ и настоящей Офертой. В случае нарушения Клиентом порядка акцепта Оферты (подачи заявки и оплаты услуг Компании) Договор признается незаключенным.",
            "6.2	Любое несогласованное с Компанией использование видео- и фотоматериалов с Мероприятия является незаконным и может послужить причиной для судебного разбирательства и привлечения к гражданско-правовой, административной и уголовной ответственности в соответствии с законодательством.",
            "6.3	В случае, если Клиент умышленно распространяет видеозаписи, документы и иной контент, предоставленный Компанией в рамках исполнения настоящего Договора, Исполнитель вправе взыскать с Заказчика штраф в размере 150 000 рублей, а также убытки в виде неполученной прибыли за оказание подобных услуг за каждый эпизод несанкционированного доступа (каждое скачивание без согласия Компании) в размере стоимости указанных услуг на сайте Компании на момент распространения.",
            "6.4	Компания не несет ответственности за несоответствие оказанных Услуг ожиданиям Клиента. При этом субъективная отрицательная оценка Клиентом оказанных Компанией Услуг не является основанием считать Услуги не оказанными или оказанными не надлежащим образом.",
            "6.5    Вся информация, предоставленная Клиенту Компанией и Спикерами в рамках мероприятия, носит исключительно информационно-новостной характер и не является инвестиционной рекомендацией.",
            "6.6    Вся информация, предоставленная Клиенту Компанией и Спикерами в рамках мероприятия, не учитывает в полном объеме личные особенности деятельности и целей Клиента, в связи с чем не гарантируют достижения поставленной цели в результате использования полученной информации, а также применяются Клиентом полностью и/или частично на собственное усмотрение.",
            "6.7    Содержание предоставленной в рамках мероприятии информации формируется Компанией и Спикерами самостоятельно и обуславливаются субъективным профессиональным восприятием по теме мероприятия.",
            "6.8    Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по настоящему Договору, если это неисполнение явилось следствием обстоятельств непреодолимой силы, которые Стороны не могли предвидеть или предотвратить.",
            "6.9    При наступлении обстоятельств, указанных в п. 6.9 настоящего Договора, каждая Сторона должна без промедления известить о них в письменном виде другую Сторону. Извещение должно содержать данные о характере обстоятельств, а также официальные документы, удостоверяющие наличие этих обстоятельств и по возможности дающие оценку их влияния на возможность исполнения Стороной своих обязательств по настоящему Договору.",
            "6.10   В случаях наступления обстоятельств, предусмотренных в п. 6.9 настоящего Договора, срок выполнения Стороной обязательств по настоящему Договору отодвигается соразмерно времени, в течение которого действуют эти обстоятельства и их последствия.",
            "6.11   Если обстоятельства, перечисленные в п. 6.9 настоящего Договора, и их последствия продолжают действовать более двух месяцев, Стороны проводят дополнительные переговоры для выявления приемлемых альтернативных способов исполнения настоящего Договора.",
        ],
    },
    {
        header: "7. Расторжение договора.",
        lines: [
            "7.1.	Настоящий договор прекращается вследствие:",
            "7.1.1  отказа Компании от исполнения Договора;",
            "7.1.2  отказа Клиента от исполнения Договора;",
            "7.1.3  соглашения Сторон;",
            "7.1.4  признания одной из Сторон несостоятельной (банкротом);",
            "7.2    Клиент вправе в любое время отказаться от Договора путём направления соответствующего заявления на электронную почту Компании, указанную в Соглашении.",
            "7.3    Стоимость услуг в случае одностороннего отказа Клиента от Договора Клиенту возвращается в порядке. Предусмотренном гл. 3 настоящего Договора.",
        ],
    },
    {
        header: "8. Прочие условия.",
        lines: [
            "8.1    Оказываемые в рамках настоящего договора услуги являются информационно-консультационными, они не являются образовательными программами, не регулируются нормами ФЗ «Об образовании», равно как и не могут быть квалифицированы как просветительская деятельность.",
        ],
    },
    {
        header: "9. Заключительные положения:",
        lines: [
            "9.1    Клиент подтверждает, что все условия настоящей Оферты ему ясны, и он принимает их безусловно и в полном объеме.",
            "9.2    Клиент дает свое согласие на информирование о мероприятиях и иных услугах Компании и (или) его партнеров путем e-mail рассылки на указанные Клиентом контактные данные. Данное согласие дается на неопределенный срок и действует до получения Компанией уведомления Клиента об отказе от рассылки на адрес e-mail info.logcap@gmail.com@",
            "9.3    Компания вправе вносить в одностороннем порядке любые изменения и дополнения к настоящему Договору публичной оферты, указанные изменения вступают в силу с момента размещения актуальной редакции на Платформе по адресу: https://conference.logcap.ru.",
            "9.4    Все споры или разногласия, возникающие между Сторонами по настоящему Договору или в связи с ним, разрешаются путем переговоров между Сторонами.",
            "9.5    Сторонами предусматривается необходимость соблюдения обязательного досудебного порядка разрешения споров в виде направления претензии.",
            "9.6    Срок рассмотрения претензии и предоставления ответа устанавливается в 30 календарных дней.",
            "9.7    В случае невозможности разрешения разногласий путем переговоров они подлежат рассмотрению в суде по правилам подсудности, установленным законодательством РФ.",
            "9.8    Признание судом недействительности какого-либо положения настоящего Договора публичной оферты не влечет за собой недействительность остальных положений.",
            "9.9    Настоящее Пользовательское соглашение и Политика конфиденциальности опубликованы на Платформе по адресу: https://conference.logcap.ru",
        ],
        extra: [
            {
                line: 2,
                start: 225,
                end: 253,
                href: "https://conference.logcap.ru",
                type: BlockExtraType.LINK,
            },
            {
                line: 8,
                start: 112,
                end: 140,
                href: "https://conference.logcap.ru",
                type: BlockExtraType.LINK,
            },
        ],
    },
    {
        header: "10. Реквизиты:",
        lines: [
            "Общество с ограниченной ответственностью «ЛОГ КЭПИТАЛ»",
            "ИНН 3666260568  ОГРН 1213600022855",
            "Адрес: 394036, г. Воронеж, ул. Комиссаржевской, д. 10, эт. 12, пом. 5",
            "Адрес электронной почты: info.logcap@gmail.com",
        ],
    },
];
