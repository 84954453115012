import clsx from 'clsx';
import { MutableRefObject, ReactNode } from 'react'

type PropsSection = {
    children: ReactNode;
    title?: string;
    styled?: string;
    refSection?: MutableRefObject<HTMLDivElement | null>;
    id?: string;
};

export const Section = (props:PropsSection) => {
    const {children, title, styled, refSection, id} = props;

    const isMargin = styled?.includes('mt-')
    const isColorRevers = styled?.includes('text-black')

    return (
        <section id={id} ref={refSection ? refSection : null} className={clsx(`w-full flex justify-center`, !isMargin && 'mt-[144px]', !!styled && styled)}>
            <div className="w-full block px-4  lg:max-w-[1140px] xl:max-w-[1320px] ">
                {!!title && <h3 className={clsx('text-mh1 sm:text-h2', isColorRevers && isColorRevers)}>{title}</h3>}
                {children}
            </div>
        </section>
    )
}
