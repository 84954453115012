import clsx from "clsx";
import { useState } from "react";

export const IconButton = ({ children, disabled, onClick, className = "", icon, inherit }: any) => {
    const Icon = (props: any) => {
        return <div {...props}>{icon}</div>;
    };
    const [hover, setHover] = useState(false);

    return (
        <div
            className={"flex items-center gap-4 cursor-pointer"}
            onClick={onClick}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            {children && <div className={"text-p1 font-semibold text-white"}>{children}</div>}
            <button
                disabled={disabled}
                className={clsx(
                    className,
                    disabled
                        ? "disabled:opacity-20"
                        : "border-white2 active:bg-grey active:text-black active:border-grey",
                    hover ? "bg-white text-black border-white" : "bg-black",
                    {
                        "bg-inherit": inherit,
                    },
                    `transition ease-in-out h-[56px] w-[56px]  text-p1 font-semibold border rounded-full flex justify-center items-center`,
                )}>
                {hover && !inherit ? (
                    <div
                        className={clsx(`
                            .fill-black {
                                path {
                                    fill: #181818;
                                }
                                svg {
                                    fill: #181818;
                                }
                                fill: #181818;
                            }
                        `)}>
                        <Icon className={"fill-black"} />
                    </div>
                ) : (
                    <Icon />
                )}
            </button>
        </div>
    );
};