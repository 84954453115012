import clsx from "clsx";
import { ReactNode } from "react";

export interface ListProps {
    // time: string;
    // title: string;
    children: ReactNode;
    lastElement?: boolean;
}

export const List = (props: ListProps) => {
    const {children, lastElement}= props;

    return (
        <div className={clsx("h-auto min-h-20 border-t border-white flex items-center justify-start ", !!lastElement && 'border-b')}>
          {children}
        </div>
    )
}
