import clsx from 'clsx'
import React from 'react'
import InputMask from 'react-input-mask';

export const Input = (props: any) => {
    const { styled, label='', placeholder, mask, name, type, required } = props;
    return (
        <div className='my-6'>
            <label >{label}
            
            <InputMask
                required={required}
                type={type}
                name={name}
                placeholder={placeholder} 
                className={clsx('baseInput', !!styled && styled)}
                mask={mask}>
                
            </InputMask>
            </label>
        </div>
    )
}
