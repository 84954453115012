import useWindowDimensions from "hooks/windowSize";
import { ReactNode, useState } from "react";
import {ReactComponent as Details} from "assets/img/arrowDetails.svg"
import {ReactComponent as Tg} from "assets/img/icon/telegram.svg"
import {ReactComponent as Site} from "assets/img/icon/site.svg"
import {ReactComponent as Youtube} from "assets/img/icon/youtube.svg"


export interface SpeakerDetailsProps {
    name: string;
    title: string | ReactNode;
    description: string | ReactNode;
    avatar: string;
    youtube?: string;
    tg?: string;
    site?: string;
}


export const SpeakerDetails = (props: SpeakerDetailsProps) => {
    const { name, description, title, avatar, youtube, tg, site } = props;
    const { width } = useWindowDimensions();

    // const arrDescription = description.split('.');
    const [rotateChevron, setRotateChevron] = useState(false);

    const handleRotate = () => setRotateChevron(!rotateChevron);

    const rotate = () => {
        if (width < 1024) {
            return rotateChevron ? "rotateY(180deg)" : "rotateY(0)";
        } else {
            return ''
        }
    } 

    return (
        <div className="speakerContainer font-sans">
            <div style={{transform: rotate()}} className='wrapContentSpeaker'>
              <div className={'infoSpeaker speakerDetails p-16 flex flex-col justify-between h-full'}  onClick={handleRotate}>
                    <div>
                        <p className="text-3xl text-left">{name}</p>
                        <p className="text-base font-medium text-left mt-6 mb-8">{title}</p>
                        <p className="text-base leading-[19px] font-normal text-left mt-4" >{description}</p>
                    </div>
                    <div>
                        <div className="flex w-1/2 items-center">
                            {tg && 
                                <div className="mr-4" onClick={e => e.stopPropagation()}>
                                    <a href={tg}><Tg className="fill-white w-8 h-8"/></a>
                                </div>
                            }
                            {youtube && 
                                <div className="mr-4" onClick={e => e.stopPropagation()}>
                                    <a href={youtube}><Youtube className="fill-white w-8 h-8"/></a>
                                </div>
                            }
                            {site && 
                                <div className="mr-4" onClick={e => e.stopPropagation()}>
                                    <a href={site}><Site className="fill-white w-8 h-8"/></a>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className={'avatarSpeaker speakerDetails'} >
                    <img src={avatar}  alt={name} className="h-full"/>
                    <div className="absolute bottom-3 w-full flex items-center justify-around  lg:hidden">
                        <p className="uppercase text-2xl">{name}</p>
                        <Details onClick={handleRotate} className="rotate-[-90deg]" />
                    </div>
                </div>
            </div>
       </div>
    )
}
