import { Button } from "components"
import { Link } from "react-router-dom"
import {ReactComponent as Youtube} from 'assets/img/icon/youtube.svg';
import {ReactComponent as Vk} from 'assets/img/icon/vk.svg';
import {ReactComponent as Telegram} from 'assets/img/icon/telegram.svg';

export const SuccessBuyTicket = () => {

  const SOCIAL_LINKS_BUY_TICKET = [
    {
        title: "YouTube",
        href: "https://www.youtube.com/@LOGCAPITAL",
        icon: <Youtube className="fill-white" />,
    },
    {
        title: "VK",
        href: "https://vk.com/logcap",
        icon: <Vk className="fill-white" />,
    },
    {
        title: "Telegram",
        href: "https://t.me/logcap",
        icon: <Telegram className="fill-white" />,
    },
];

  return (
    <div className="bg-black h-[100vh] w-[100vw] p-11 text-white flex flex-col justify-evenly items-center">
      <div className="w-3/4 text-center">
        <h3 className="text-3xl">Поздравляем с успешной покупкой билета на конференцию LOGCAP!</h3>
        <p className="text-xl mt-7">Ожидайте получения билета на почту указанную вами ранее.</p>
      </div>

      <div className="flex flex-col items-center">
        <Link to={'/'}>
          <Button>Вернуться на главную</Button>
        </Link>
        <span className="my-8">Так же рекомендуем посетить и подписаться на нас в социальных сетях</span>
        <div className="flex justify-evenly items-start w-full">
          {
            SOCIAL_LINKS_BUY_TICKET.map((el, index) => (
                <a key={index} href={el.href} target="_blank" rel="noreferrer">
                    {el.icon}
                </a>
            ))
          }
        </div>
      </div>

      <p className="text-lg text-yellow-400">Если письмо с билетом не пришло в течении 24ч, сообщите на почту: &nbsp;
        <a className="text-yellow-400 text-xl my-12" href="mailto: info.logcap@gmail.com">
            info.logcap@gmail.com
        </a>
      </p>

    </div>
  )
}
