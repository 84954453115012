import { SpeakerDetails } from "./speakerDetails/SpeakerDetails"
import { Card } from "components/baseComponents"
import logunov from 'assets/img/speakersAvatar/logunov.jpg'
import chudaev from 'assets/img/speakersAvatar/chudaev2.jpg'
import zilnikov from 'assets/img/speakersAvatar/Zilnikov.webp'
import slukin from 'assets/img/speakersAvatar/slukin2.jpg'
import darsaliya from 'assets/img/speakersAvatar/darsaliya.webp'
import alekseev from 'assets/img/speakersAvatar/alekseev.webp'
import klevcov from 'assets/img/speakersAvatar/klevcov.jpg'
import pirogov from 'assets/img/speakersAvatar/pirogov.jpg'
import stukalin from 'assets/img/speakersAvatar/Stukalin.webp'

export const SPEAKERS_DATA = [
    {
        id: 0,
        name: 'Сергей Логунов',
        title: <span>Генеральный директор <br/> ООО "Лог Кэпитал"</span>,
        description: '',
        avatar: logunov,
        tg: 'https://t.me/loguknow',
    },
    {
        id: 1,
        name: 'Станислав Чудаев',
        title: <span>Ведущий трейдер <br/> проп-трейдинговой компании <br/> Lite-Invest</span>,
        description: '',
        avatar: chudaev,
        youtube: 'https://youtu.be/9qWdALT7UQE?si=Ticqd4XthN-ga2pH'
    },
    {
        id: 2,
        name: 'Сергей Слукин',
        title: <span>Руководитель отдела HFT и направления проп-трейдинга <br/> АО «ФИНАМ»</span>,
        description: '',
        avatar: slukin,
        site: 'https://www.finam.ru/',
    },
    {
        id: 3,
        name: 'Дмитрий Жильников',
        title: 'Топ-трейдер ООО "Лог Кэпитал"',
        description: '',
        avatar: zilnikov,
        tg: 'https://t.me/remeslennikXXI',
    },
    {
        id: 4,
        name: 'Игорь Дарсалия',
        title: <span>Директор филиала <br/> БКС Мир Инвестиций.</span>,
        description:<span>Профессиональный путь в мире рынка ценных бумаг с 2008 года:<br/>Тройка Диалог, <br/>Сберанк, <br/>Альфа-Капитал,<br/> Открытие Брокер.</span>,
        avatar: darsaliya,
    },
    {
        id: 5,
        name: 'Сергей Алексеев',
        title: <span>Основатель и ведущий трейдер проп-трейдинговой компании<br/> Live Investing Group</span>,
        description: '',
        avatar: alekseev,
        youtube: 'www.youtube.com/@LIVEInvestingGroup',
        site: 'https://alexeevlive.com'
    },
    {
        id: 6,
        name: 'Антон Клевцов',
        title: 'Трейдер, инвестор, предприниматель, эксперт по анализу макроэкономики.',
        description: 'Автор одноимённого канала на YouTube.',
        avatar: klevcov,
        tg: 'https://t.me/traderanswers',
        youtube: 'https://youtube.com/@klevtsovanton?si=wp71Hn064O1fJBcv'
    },
    {
        id: 7,
        name: 'Сергей Пирогов',
        title: 'Инвестор, основатель Invest Heroes',
        description: '',
        avatar: pirogov,
        tg: 'https://t.me/InvestHeroes',
        site: 'https://invest-heroes.ru/',
    },
    {
        id: 8,
        name: 'Денис Стукалин',
        title: <span>Сооснователь и ведущий трейдер компании XELIUS</span>,
        description: '',
        avatar: stukalin,
        site: 'https://xelius.pro/'
    },
]


export const Speakers = () => {
  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4 pb-12 mt-24 justify-items-center">
        {SPEAKERS_DATA.map((el) => (
            <Card key={el.id} >
                <SpeakerDetails name={el.name} description={el.description} title={el.title} avatar={el.avatar} tg={el.tg} site={el.site} youtube={el.youtube} />
            </Card>
        ))}
    </div>
  )
}
