import { Logo } from "./logo/Logo";
import { Button } from "components/baseComponents";
import { Section } from "../section";

export const NavBar = ({ children, refs, }: any) => {

    const jumpToSection = (ref:any) => () => {
        ref.current?.scrollIntoView({
            behavior: 'smooth'
          });
    }

    return (
        <nav
            className={"w-full z-50 absolute top-6 bg-transparent bg-opacity-0 hidden bg-black2 z-100 lg:block"}>
                <Section styled="mt-0">
                    <div className="flex items-center justify-between relative">
                        <Logo />
                        <div className="w-[512px] flex justify-between absolute left-[425px]" >
                            {
                                refs?.map((el:any) => (
                                    <button key={el.id} onClick={jumpToSection(el.ref)}>
                                        {el.name}
                                    </button>
                                ) )
                            }
                        </div>

                        <Button >
                            Секция
                        </Button>
                    </div>
                </Section>
        </nav>
    );
};
