import clsx from "clsx";
import { ReactNode } from "react";

export interface CardProps {
  styled?: string;
    children?: ReactNode;
    avatar?: string;
}

export const Card = (props:CardProps) => {
    const {styled='w-full h-[612px] sm:w-[409px]', children } = props;

    const isBorderColor = styled.includes('border-black')

    return (
      <div className={clsx("border", !isBorderColor && 'border-white', styled)}>
          {children}
      </div>
    )   
}
