import { BlockExtraType, ContentBlock } from "components/shared/publicInfo/PublicInfo";
            // "Адрес электронной почты: info.logcap@gmail.com",


export const policyData: ContentBlock[] = [
    {
        lines: [
            "Настоящий документ «Политика конфиденциальности» (далее – по тексту – «Политика») представляет собой правила использования Платформы – «Logcap.ru» в лице Общества с ограниченной ответственностью «ЛОГ КЭПИТАЛ» (ИНН 3666260568  ОГРН 1213600022855, в лице генерального директора Логунова Сергея Сергеевича, далее – Компания) персональной информации Пользователей (Далее – Пользователей), которую Компания может получить о Пользователе во время использования им любого из сайтов, сервисов, служб, программ, продуктов или услуг Компании (далее – Платформа) и в ходе исполнения Компанией любых соглашений и договоров с Пользователем Использование Платформы означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в ней условиями обработки его персональной информации; в случае несогласия с этими условиями Пользователь должен воздержаться от использования Платформы. ",
        ],
    },
    {
        header: "Термины",
        lines: [
            "•	Компания – юридическое лицо Общество с ограниченной ответственностью «ЛОГ КЭПИТАЛ» (ИНН 3666260568  ОГРН 1213600022855);",
            "•  Платформа - веб-сайт https://logcap.ru/courses, предоставляющий доступ к базе данных, видеоматериалов, мероприятиям, и консультативным услугам наставников. ",
            "•  Пользователь – любое физическое или юридическое лицо, использующее Платформу.",
            "•  Персональные данные - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных);",
            "•  Оператор персональных данных (оператор) - государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;",
            "•  Обработка персональных данных - любое действие (операция) или совокупность действий (операций) с персональными данными, совершаемых с использованием средств автоматизации или без их использования. Обработка персональных данных включает в себя в том числе:",
            "•  сбор",
            "•  запись",
            "•  систематизацию",
            "•  накопление",
            "•  хранение",
            "•  уточнение (обновление, изменение);",
            "•  извлечение",
            "•  использование",
            "•  передачу (распространение, предоставление, доступ);",
            "•  обезличивание",
            "•  блокирование",
            "•  удаление",
            "•  уничтожение",
            "•  Автоматизированная обработка персональных данных - обработка персональных данных с помощью средств вычислительной техники;",
            "•  Уничтожение персональных данных - действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных;",
            "•  Трансграничная передача персональных данных - передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому лицу.",
        ],
        extra: [
            {
                line: 1,
                start: 24,
                end: 49,
                href: "https://logcap.ru/courses",
                type: BlockExtraType.LINK,
            },
        ],
    },
    {
        header: "1. Общие положения политики",
        lines: [
            "1.1	Настоящая Политика является неотъемлемой частью Договора оферты на оказание консультативных услуг (далее – «Оферта»), размещенного и доступного в сети Интернет по адресу: https://conference.logcap.ru, а также иных заключаемых с Пользователем договоров, когда это прямо предусмотрено их условиями.",
            "1.2	Настоящая Политика составлена в соответствии с Федеральным законом «О персональных данных» № 152-ФЗ от 27 июля 2006 г., а также иными нормативно-правовыми актами Российской Федерации в области защиты и обработки персональных данных и действует в отношении всех персональных данных, которые Компания может получить от Пользователя, являющегося стороной по Оферте.",
            "1.3	Компания имеет право вносить изменения в настоящую Политику. При внесении изменений в заголовке Политики указывается дата последнего обновления редакции. Новая редакция Политики вступает в силу с момента ее размещения на Платформе, если иное не предусмотрено новой редакцией Политики.",
            "1.4	К настоящей Политике, включая толкование ее положений и порядок принятия, исполнения, изменения и прекращения, подлежит применению законодательство Российской Федерации.",
            "1.5	Компания вправе проводить Мероприятие как самостоятельно, так и путем привлечения к проведению Мероприятия третьих лиц.",
        ],
        extra: [
            {
                line: 0,
                start: 175,
                end: 203,
                href: "https://conference.logcap.ru",
                type: BlockExtraType.LINK,
            },
        ],
    },
    {
        header: "2. Персональная информация Пользователей, которую обрабатывает Компания",
        lines: [
            "2.1	Под персональной информацией в настоящей Политике понимается:",
            "2.1.1	Информация, предоставляемая Пользователем самостоятельно при регистрации (создании учётной записи) или в процессе использования Платформы, включая персональные данные Пользователя. Обязательная для предоставления информация помечена специальным образом. Иная информация предоставляется Пользователем на его усмотрение. В перечень такой информации входит:",
            "2.1.1.1	Фамилия, Имя, Отчество;",
            "2.1.1.2	Номер телефона",
            "2.1.1.3	Адрес электронной почты.",
            "2.2	Данные, которые передаются в автоматическом режиме Платформе в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе:",
            "2.2.1  IP-адрес,",
            "2.2.2  данные файлов cookie,",
            "2.2.3  информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к Платформе),",
            "2.2.4  технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к Платформе, адреса запрашиваемых страниц и иная подобная информация;",
            "2.2.5  Иная информация о Пользователе, обработка которой предусмотрена условиями использования Платформы.",
            "2.3    Настоящая Политика применима только к информации, обрабатываемой в ходе использования Платформы. Компания не контролирует и не несет ответственность за обработку информации сайтами третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Платформе.",
            "2.4    Компания не проверяет достоверность персональной информации, предоставляемой Пользователем, и не имеет возможности оценивать его дееспособность.",
        ],
    },
    {
        header: "3. Цели обработки персональной информации Пользователей.",
        lines: [
            "3.1	Компания собирает и хранит только ту персональную информацию, которая необходима для предоставления сервисов или исполнения соглашений и договоров с Пользователем, за исключением случаев, когда законодательством предусмотрено обязательное хранение персональной информации в течение определенного законом срока.",
            "3.2    Персональную информацию Пользователя Компания обрабатывает в следующих целях:",
            "3.2.1    идентификация стороны в рамках исполнения Пользовательского соглашения;",
            "3.2.2    предоставление Пользователю персонализированных сервисов и услуг, а также исполнение соглашений и договоров;",
            "3.2.3    направление уведомлений, запросов и информации, касающихся использования Платформы, исполнения соглашений и договоров, а также обработка запросов и заявок от Пользователя;",
            "3.2.4    улучшение качества работы Платформы, удобства его использования для Пользователя, разработка новых услуг и сервисов;",
            "3.2.5    таргетирование рекламных материалов;",
            "3.2.6    проведение статистических и иных исследований на основе обезличенных данных.",
        ],
    },
    {
        header: "4. Условия обработки персональной информации Пользователей и её передачи третьим лицам.",
        lines: [
            "4.1	В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц.",
            "4.2    Компания вправе передать персональную информацию Пользователя третьим лицам в следующих случаях:",
            "4.2.1  Пользователь выразил согласие на такие действия;",
            "4.2.2  Передача необходима для использования Пользователем определенного сервиса либо для исполнения определенного соглашения или договора с Пользователем;",
            "4.2.3  Передача необходима для функционирования и работоспособности самой Платформы;",
            "4.2.4  Передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры;",
            "4.2.5  Такая передача происходит в рамках продажи или иной передачи бизнеса (полностью или в части), при этом к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации;",
            "4.2.6  В целях обеспечения возможности защиты прав и законных интересов Компании или третьих лиц в случаях, когда Пользователь нарушает условия Договора, настоящую Политику или иные документы, регулирующие деятельность Платформы.",
            "4.2.3  В результате обработки персональной информации Пользователя путем ее обезличивания получены обезличенные статистические данные, которые передаются третьему лицу для проведения исследований, выполнения работ или оказания услуг по поручению Компании.",
        ],
    },
    {
        header: "5. Изменение и удаление персональной информации. Обязательное хранение данных.",
        lines: [
            "5.1	Пользователь может в любой момент изменить (обновить, дополнить) предоставленную им персональную информацию или её часть, а также потребовать удаления всякой персональной информации о Пользователе, обратившись к Компании по контактам в разделе 9. «Контакты».",
            "5.2	Права, предусмотренные п. 5.1. настоящей Политики могут быть ограничены в соответствии с требованиями законодательства. Например, такие ограничения могут предусматривать обязанность Компании сохранить измененную или удаленную Пользователем информацию на срок, установленный законодательством, и передать такую информацию в соответствии с законодательно установленной процедурой государственному органу.",
        ],
    },
    {
        header: "6. Обработка персональной информации при помощи файлов Cookie и счетчиков.",
        lines: [
            "6.1	Файлы cookie, передаваемые Платформой оборудованию Пользователя и оборудованием Пользователя Платформе, могут использоваться Компанией для предоставления Пользователю персонализированных сервисов, для таргетирования рекламы, которая показывается Пользователю, в статистических и исследовательских целях, а также для улучшения Платформы.",
            "6.2	Пользователь осознает, что оборудование и программное обеспечение, используемые им для посещения сайтов в сети интернет могут обладать функцией запрещения операций с файлами cookie, а также удаления ранее полученных файлов cookie.",
            "6.3	Компания вправе установить, что предоставление определенного сервиса или услуги возможно только при условии, что прием и получение файлов cookie разрешены Пользователем.",
            "6.4	Структура файла cookie, его содержание и технические параметры определяются Компанией и могут изменяться без предварительного уведомления Пользователя.",
        ],
    },
    {
        header: "7. Защита персонально информации Пользователя.",
        lines: [
            "7.1	Компания предпринимает необходимые и достаточные организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.",
        ],
    },
    {
        header: "8. Изменение Политики конфиденциальности.",
        lines: [
            "8.1    Компания имеет право вносить изменения в настоящую Политику конфиденциальности. При внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики. Действующая редакция постоянно доступна на странице по адресу: https://conference.logcap.ru.",
        ],
        extra: [
            {
                line: 0,
                start: 353,
                end: 381,
                href: "https://conference.logcap.ru",
                type: BlockExtraType.LINK,
            },
        ],
    },
    {
        header: "9. Контакты и вопросы по персональным данным",
        lines: [
            "9.1    Все предложения, вопросы, запросы и иные обращения по поводу настоящей Политики и использования своих персональных данных Пользователь вправе направлять Компании:",
            "9.1.1  по адресу электронной почты: info.logcap@gmail.com",
            "9.1.2  по почтовому адресу: Воронеж, ул. Комиссаржевской 10, оф. 1201",
        ],
    },
    {
        header: "Дата публикации настоящей редакции: 20.05.2024 г.",
    },
];
