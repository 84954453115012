import { Portal } from 'components/shared/portal/Portal'
import { PublicInfo } from 'components/shared/publicInfo/PublicInfo'
import { useState } from 'react'
import {ReactComponent as Close} from "assets/img/icon/Cross.svg"

export const ModalData = (props: any) => {
    const {title, header, data} = props;
    const [isOpen, setIsOpen] = useState(false)

    return (
        <>
            <button onClick={() => setIsOpen(prev => !prev)} className='text-start w-auto md:w-48'>
                {title}
            </button>

            <Portal isOpen={isOpen}>
            <button className="closeButton" onClick={() => setIsOpen(prev => !prev)}>
                <Close height={36} width={36}/>
            </button>
              <div className='text-white p-12'>
                <h3 className='text-h3 text-center mb-12'>{header}</h3>
                <PublicInfo content={data}/>
              </div>
            </Portal>
        </>
    )
}
