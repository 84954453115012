import { Card } from "components/baseComponents";
import { EventForm } from "./eventForm/EventForm";
import { useEffect, useState } from "react";

export const EVENT_COST = [
    {
        id: 1,
        type: 'Стандартный',
        cost: '5 490 ₽',
        access: [
                'Доступ в конференц-зал',
                'Общение со спикерами',
                'Кофе-брейки / обед',
                'After Party',
        ],
        tariff: 'Standard'
    },
    {
        id: 12,
        type: 'Премиум',
        cost: '13 490 ₽',
        access: [
                'Доступ в конференц-зал',
                'Общение со спикерами',
                'Кофе-брейки / обед',
                'After Party',
                '1-3 ряд в зале',
                'Мерч LOGCAP (Свитшот + Футболка)'
        ],
        tariff: 'Premium'
    },
];

export const EventCost = () => {
    const [balanceTicket, setBalanceTicket] = useState<any>({
        Premium: 0,
        Standard: 0,
    })

    
    const getTickets = async () => {
        const response = await fetch(`https://conference.logcap.ru/clients/free-bookings`,  {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;'
            }
          })
        const resp = await response.json()
        setBalanceTicket({
            Premium: resp.premium,
            Standard: resp.standard
        })
    }

    useEffect(()=> {
        getTickets()
    },[]);

    return (
        <div className="h-full ">
            <div className="flex items-center justify-center  xl:justify-end flex-col lg:flex-row mt-24 ">
                {
                    EVENT_COST.map((el) => (
                        <Card key={el.id} styled="border-black text-black w-[336px] h-[504px] sm:w-[424px] sm:h-[566px] mt-6 sm:ml-6 bg-black text-white p-8 flex flex-col justify-between">
                            <div key={el.id}>
                                <p className="text-base">{el.type}</p>
                                <p className="text-6xl mt-2">{el.cost}</p>
                                <div className="text-base mt-12">
                                    {
                                        el.access.map((item, i) => 
                                            <li key={i} className="leading-[2]">{item}</li>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="flex justify-center ">
                                <div className="flex flex-col">
                                    { balanceTicket[el.tariff] <= 0 && <p className="text-lg">Билеты закончились</p> }
                                    <EventForm tariff={el.tariff} isDisabled={balanceTicket[el.tariff] <= 0 } />
                                </div>
                            </div>
                        </Card>
                    ))
                }
            </div>
        </div>
    ) 
}   
