import { Button } from "components"
import { Link } from "react-router-dom"

export const ErrorBuyTicket = () => {
  return (
    <div className="bg-black h-[100vh] w-[100vw] p-11 text-red-600 flex flex-col items-center">
      <p className="text-2xl">Произошла ошибка при совершении платежа,</p>
      <p  className="text-2xl">попоробуйте совершить платеж позже или сообщите об этом в поддержку!</p>

      <a className="text-yellow-400 text-2xl my-12" href="mailto: info.logcap@gmail.com">
          info.logcap@gmail.com
      </a>
      
      <Link to={'/'}>
        <Button>
          Вернуться на главную
        </Button>
      </Link>
    </div>
  )
}
