import {createPortal} from 'react-dom';
import { ReactNode } from 'react';
import clsx from 'clsx';

export interface PortalBody {
    isOpen: boolean;
    children: ReactNode;
    styled?: string;
}

export const Portal = (props: PortalBody) => {
    const {isOpen, children, styled} = props;
    const modalElem = document.getElementById('portal-modal')!;

    if(!isOpen) return null;

    return createPortal(
      <div className={clsx('modal', !!styled ? styled : 'bg-black')} >
        {children}
      </div>
      , modalElem
    )
}