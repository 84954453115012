import { Portal } from "components/shared/portal/Portal";
import { useState } from "react";
import { Button, InputMask } from "components/baseComponents";


export const EventForm = (props: any) => {
    const { tariff, isDisabled } = props;
    const [isOpen, setIsOpen] = useState(false);

    const onSubmit = async (e: any) => {
        e.preventDefault();
        const form = e.target;
        const data = new FormData(form);
        const phoneNumber = data.get('phone') as string;
      
        const response = await fetch(`https://conference.logcap.ru/clients/create-payment`,  {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                firstName: data.get('firstName'),
                lastName: data.get('lastName'),
                middleName: data.get('middleName'),
                email: data.get('email'),
                phoneNumber: phoneNumber.replace(/\D/g, ''),
                tariff
            })
          });

        if (response.ok) {
            const resp = await response.text()
            window.location.href=resp
        } else {
            alert(
                `Ошибка HTTP: ${response.status}
                Попробуйте позже или сообщите в поддержку!`
            );
        }
    };

    return (
        <>
            <Button disabled={isDisabled} onClick={() => setIsOpen(prev => !prev)}>
                Купить билет
            </Button>

            <Portal isOpen={isOpen} styled="bg-[#181818d1] flex items-center justify-center">
                <div className='text-white pt-3 pb-0 px-3 sm:p-12   w-full h-full xl:h-auto xl:w-1/3 bg-[#4b4b4bd9]'>
                    <h3 className='text-h3 text-center text-white'>ДАННЫЕ ДЛЯ МЕРОПРИЯТИЯ</h3>
                    <form onSubmit={onSubmit}>
                        <InputMask name="lastName" label="Фамилия" required placeholder="Фамилия" />
                        <InputMask name="firstName" label="Имя" required placeholder="Имя" />
                        <InputMask name="middleName" label="Отчество" placeholder="Отчество" />
                        <InputMask name='email' type='email' required label="Электроная Почта" placeholder="exemple@mail.ru" />
                        <InputMask name='phone' type='phone' label="Номер телефона" placeholder="+7 (999) 999-99-99" mask='+7 (999) 999-99-99' />
                        <div className="flex-col sm:flex-row w-full flex items-center justify-evenly">
                            <Button className='w-full sm:w-auto' type="submit">
                                Купить билет
                            </Button>
                            <Button className='w-full sm:w-auto mt-6 sm:mt-0'  reverseColor onClick={() => setIsOpen(prev => !prev)}>
                                Отмена
                            </Button>
                        </div>
                    </form>
                </div>
            </Portal>
        </>
    );
};
