import { Button, Step } from 'components/baseComponents'
import welcomeImg from 'assets/img/welcomeImg.png'
import { EventInfo } from 'components/shared'
import {ReactComponent as Calendar} from 'assets/img/icon/Calendar.svg'
import {ReactComponent as Location} from 'assets/img/icon/Location.svg'
import {ReactComponent as Watch} from 'assets/img/icon/Watch.svg'
import { jumpToSection } from 'components/shared/helpers/helpers'
import useWindowDimensions from 'hooks/windowSize'

export const ARR_INFO = [
    {
        id: 0,
        Icon: <Calendar />,
        description: '28 сентября',
    },
    {
        id: 1,
        Icon: <Watch />,
        description: 'Начало в 10:00',
    },
    {
        id: 2,
        Icon: <Location fill='white' />,
        description: 'г. Воронеж, проспект Революции, 38',
        link: "https://yandex.ru/maps/org/marriott_hotel/74896326928/?ll=39.206504%2C51.667870&z=17.01",
    }
]

export const ConferenceHeader = ({refByTicket}:any) => {
    const { width } = useWindowDimensions();
  const STEPS = [
    {
        title:'8 спикеров',
        description: "Эксперты-практики, которые управляют портфелями на разных рынках."
    },
    {
        title:'5 часов',
        description: "Полезной информации от специалистов, которую вы не найдете в интернете."
    },
    {
        title:'150+ участников',
        description: "Представители бирж и брокеров, эмитенты и крупные финансовые блогеры."
    },
]
  
    return (
    <div className="flex flex-col items-center xl:items-start text-white">
        
        <div className="w-auto xl:w-full xl:max-w-[1100px] flex flex-col-reverse items-center md:flex-col md:items-start">
            <EventInfo arrData={ARR_INFO} />
            <h1 className="text-3xl text-justify uppercase mt-10 lg:text-h1 sm:text-h2 pb-6 md:pb-0">
                Конференция 
                <p className="text-right 2xl:relative 2xl:w-max 2xl:left-[425px]">
                    по трейдингу
                </p>
                и инвестициям
            </h1>
        </div>

        <div className='w-full flex md:block justify-center relative mt-14'>
            <Button className='w-full md:w-auto left-0 md:relative md:left-[435px]' onClick={jumpToSection(refByTicket, width)}>
                Принять участие
            </Button>
        </div>
        <div
            className={
                "max-w-[1024px] xl:max-w-7xl flex w-full my-[64px] lg:my-[120px] flex-wrap flex-col lg:flex-row pl-12 lg:pl-0"
            }>
                {STEPS.map((el, index) => (
                    <Step
                        key={index}
                        title={el.title}
                        description={el.description}
                        lastChild={STEPS.length === index + 1}
                    />
                ))}
        </div>

        <img src={welcomeImg} alt="WelcomeIMG" />

    </div>
  )
}
