import { Button } from 'components'
import { Link } from 'react-router-dom'

export const NotFoundPage = () => {
  return (
    <div className='bg-black h-[100vh] w-[100vw] p-11 text-white flex flex-col justify-center items-center'>
        Такой страницы не существует

        <Link className='mt-11' to={'/'}>
            <Button>Вернуться на главную</Button>
        </Link>
    </div>
  )
}
