export enum BlockExtraType {
    LINK = "link",
}
export interface ContentBlockExtra {
    line: number;
    start: number;
    end: number;
    type: BlockExtraType;
    href: string;
}
export interface ContentBlock {
    header?: string;
    lines?: string[];
    extra?: ContentBlockExtra[];
}
export interface IJsonContentProps {
    content: ContentBlock[];
}

export const PublicInfo = ({ content }: IJsonContentProps) => {
    return (
        <>
            <div className={"relative"}>
                <div className={"flex flex-col"}>
                    {content.map((block, i) => (
                        <div key={i} className={"text-white lg:max-w-[1600px] pb-10"}>
                                {block.header && (
                                    <div className={"text-h4 mb-6 lg:mb-4 uppercase"}>
                                        {block.header}
                                    </div>
                                )}
                                <div className={"flex flex-col gap-1"}>
                                    {block.lines?.map((el, i) => {
                                        if (block.extra) {
                                            const extra = block.extra.filter((el) => el.line === i);
                                            const extraLine = extra.reduce(
                                                (acc, extra) => {
                                                    if (extra.type === BlockExtraType.LINK) {
                                                        const a = el.slice(0, extra.start);
                                                        const b = el.slice(extra.start, extra.end);
                                                        const c = el.slice(extra.end, -1);

                                                        acc.push(a);
                                                        acc.push(
                                                                <a
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="cursor-pointer underline text-blue-400"
                                                                    href={extra.href}>
                                                                    {b}
                                                                </a>,
                                                        );
                                                        acc.push(c);

                                                        return acc;
                                                    } else {
                                                        return acc;
                                                    }
                                                },
                                                [] as (JSX.Element | string)[],
                                            );

                                            if (!!extraLine.length) {
                                                return (
                                                    <div key={i} className="flex flex-col gaspan-1">
                                                       <span key={i}>{extraLine}</span>
                                                    </div>
                                                );
                                            }
                                        }

                                        return <div key={i}>{el}</div>;
                                    })}
                                </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};
