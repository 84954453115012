import clsx from "clsx";

export const Button = ({
    children,
    disabled,
    onClick,
    className = "",
    fullWidth,
    reverseColor,
    ...props
}: any) => {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            {...props}
            className={clsx(
                `transition ease-in-out  px-10 py-[18px]  text-p1 font-semibold border`,
                reverseColor ? "bg-black text-white" : "bg-white text-black",
                disabled
                    ? "disabled:opacity-20"
                    : reverseColor
                    ? "active:bg-grey active:text-black active:border-grey hover:bg-white hover:text-black  hover:border-black"
                    : "active:bg-grey active:text-black active:border-grey hover:bg-black hover:text-white  hover:border-white",
                {
                    "w-[100%]": fullWidth,
                },
                className,
            )}>
            {children}
        </button>
    );
};