import { List } from "components/baseComponents"

export const FAQ_DATA = [
    {
        id: 0,
        title: "ЕСТЬ ЛИ ОГРАНИЧЕНИЯ ПО ВОЗРАСТУ?",
        description: "Вход на конференцию от 16 лет"
    },
    {
        id: 1,
        title: "ЧТО НЕОБХОДИМО ВЗЯТЬ НА КОНФЕРЕНЦИЮ?",
        description: "Необходимо только ваше присутствие. Дресс-код: повседневный / деловой"
    },
    {
        id: 2,
        title: "КАК МОЖНО ОПЛАТИТЬ БИЛЕТ?",
        description: "Только через сервис - YooKassa"
    },
    {
        id: 3,
        title: "ВОЗМОЖНО ЛИ ВЕРНУТЬ БИЛЕТ?",
        description: "Да. Пришлите квитанцию об оплате на info.logcap@gmail.com и в течение 24 часов мы вернем средства."
    },
  
]

export const Faq = () => {

    const info = (str: string) => {
        if(str.includes('info.logcap@gmail.com')) {
            const arrStr = str.split('info.logcap@gmail.com')
            return <p className="text-">
                {arrStr[0]}
                <span className="text-blue-400">info.logcap@gmail.com</span> {arrStr[1]}
            </p>
        }
        return <p>{str}</p>
    } 
  return (
    <div className="mt-24 flex flex-col items-end">
        <div className="w-full lg:w-[885px] font-sans">
        {
            FAQ_DATA.map((el) => (
                <List key={el.id}>
                    <details className="w-full">
                        <div className="w-2/3 text-base pb-14" >{info(el.description)}</div>
                        <summary className="h-[120px] text-2xl uppercase">
                            {el.title}
                        </summary>
                    </details>
                </List>
            ))
            
        }
        </div>
    </div>
  )
}
